<template>
    <tfoot>
        <tr style="background: #f7f7f7;">
            <td :colspan="column" style="border-top: none;">
                <div class="float-left mr-2">
                    <b-form-select v-model="limit" :options="options" @change="getDataPaging(1, limit)" size="sm">
                    </b-form-select>
                </div>
                <div class="float-left mt-1" style="color: #7f7f7f;">
                    <span class="text-paging">{{ items.firstItem }}-{{ items.lastItem }} of {{ items.total }}</span>
                </div>
                <div class="float-right">
                    <ul class="pagination pagination-sm m-0">
                        <li class="page-item" v-if="items.page > 1">
                            <a class="page-link link-click" v-on:click="getDataPaging(1, items.per_page)">&laquo;</a>
                        </li>
                        <li class="page-item disabled" v-else>
                            <a class="page-link">&laquo;</a>
                        </li>
                        <li v-bind:class="'page-item ' + (items.page == paging ? 'active ' : '') + (paging > 0 ? '' : 'disabled')"
                            v-for="(paging, index) in pagings" :key="index">
                            <a v-if="items.page == paging" class="page-link">{{ paging }}</a>
                            <a v-else-if="paging > 0" class="page-link link-click"
                                v-on:click="getDataPaging(paging, items.per_page)">{{ paging }}</a>
                            <a v-else class="page-link">{{ paging }}</a>
                        </li>
                        <li class="page-item" v-if="items.page < items.totalPage">
                            <a class="page-link link-click"
                                v-on:click="getDataPaging(items.totalPage, items.per_page)">&raquo;</a>
                        </li>
                        <li class="page-item disabled" v-else>
                            <a class="page-link">&raquo;</a>
                        </li>
                    </ul>
                </div>
            </td>
        </tr>
    </tfoot>
</template>
    
<script>
import { getPagings } from "../../common/functions"
import config from "../../common/config"
import { getPagingLimit, savePagingLimit } from "../../common/storage"

export default {
    props: {
        column: String,
        items: {},
        callBack: {
            type: Function
        }
    },
    data() {
        return {
            pagings: [],
            page: 1,
            limit: 10,
            options: [
                { value: 5, text: '5' },
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 50, text: '50' },
                { value: 100, text: '100' },
                { value: 200, text: '200' },
                { value: 500, text: '500' },
            ]
        };
    },
    methods: {
        setPaging() {
            if (this.items) {
                this.items.per_page = this.limit;
                this.items.totalPage = parseInt(this.items.total / this.items.per_page) + (parseInt(this.items.total % this.items.per_page) > 0 ? 1 : 0);
                this.items.firstItem = (parseInt(this.items.page) > 0) ? ((parseInt(this.items.per_page) * (parseInt(this.items.page) - 1)) + (this.items.total ? 1 : 0)) : 0;
                this.items.lastItem = (parseInt(this.items.page) > 0) ? ((parseInt(this.items.page) * parseInt(this.items.per_page)) > this.items.total ? this.items.total : (parseInt(this.items.page) * parseInt(this.items.per_page))) : 0;
                this.pagings = getPagings(this.items);
            }
        },
        async getDataPaging(page, perPage) {
            this.callBack({ page: parseInt(page), perPage: parseInt(perPage) });
            if (perPage) savePagingLimit(perPage);
        }
    },
    mounted() {
        const limit = getPagingLimit();
        if (limit) this.limit = limit;
        else this.limit = config.PER_PAGE;
        this.setPaging();
    },
    watch: {
        items() {
            this.setPaging();
        }
    }
};
</script>
    